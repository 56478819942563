import {useContext, useState, useEffect} from "react";
import {Link, Route, Routes, useLocation} from "react-router-dom";
import {Button, Layout, Menu, Popover, theme, Spin, Typography} from "antd";
import UserContext from "@context/UserContext.js";
import getNavigate from "../nav";
import getRoutes from "../routes";
import "./style.css";

const {Content, Sider} = Layout;

const DefaultLayout = ({logout}) => {
  const [isLoading, setIsLoading] = useState(true);
  const user = useContext(UserContext);
  const location = useLocation();
  const {pathname} = location;
  const {
    token: {colorBgContainer, borderRadiusLG},
  } = theme.useToken();

  const nav = user
    ? getNavigate(user).map((el) => ({
        ...el,
        label: <Link to={el.url}>{el.label}</Link>,
      }))
    : [];

  useEffect(() => {
    if (pathname !== "/" && !isLoading) {
      toggleLoading();
    }
  }, [pathname]);

  const routesLocal = user ? getRoutes(user) : [];

  const getSelectedNav = () => {
    return [`${nav.findIndex((el) => el.url === pathname) + 1}`];
  };

  const toggleLoading = () => setIsLoading((prevState) => !prevState);

  const siderStyle = {
    overflow: "auto",
    height: "100vh",
    position: "fixed",
    insetInlineStart: 0,
    top: 0,
    bottom: 0,
    scrollbarWidth: "thin",
    scrollbarColor: "unset",
  };

  const layoutStyle = {
    transitionProperty: "margin-inline-start",
    transitionDuration: ".3s",
  };

  const logoStyles = {
    width: "188px",
    display: "flex",
    justifyContent: "center",
    transitionProperty: "width",
    transitionDuration: ".2s",
    paddingTop: "7px",
    position: "fixed",
    overflow: "hidden",
    top: 0,
    left: 0,
    zIndex: 100,
    background: colorBgContainer,
  };

  const fioStyles = {
    minHeight: "50px",
    height: "50px",
    transitionProperty: "width",
    transitionDuration: ".2s",
    width: "188px",
    position: "fixed",
    bottom: 0,
    left: 0,
    background: colorBgContainer,
  };

  return (
    <Layout style={{height: "100vh"}}>
      <Sider trigger={null} style={{background: colorBgContainer, ...siderStyle}}>
        <div style={{position: "relative", width: "100%"}}>
          <div style={logoStyles}>
            <img src="/logo.svg" alt="image" />
          </div>
          <Menu mode="inline" selectedKeys={getSelectedNav()} items={nav} style={{margin: "55px 0"}} />
          <div style={fioStyles}>
            <Typography.Text ellipsis style={{cursor: "pointer", height: "100%", padding: "10px"}}>
              <Popover
                placement={"bottom"}
                content={
                  <div>
                    <Button onClick={() => logout()}>Выход</Button>
                  </div>
                }
              >
                {user && user.fullName}
              </Popover>
            </Typography.Text>
          </div>
        </div>
      </Sider>
      <Layout style={{display: "flex", flexDirection: "column", marginInlineStart: 200, ...layoutStyle}}>
        <Content
          style={{
            margin: 10,
            padding: 10,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            flex: 1,
            overflowY: "auto",
          }}
        >
          <Routes>
            {routesLocal &&
              routesLocal.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={
                      <Spin spinning={isLoading}>
                        <route.component toggleLoading={toggleLoading} />
                      </Spin>
                    }
                  />
                ) : null;
              })}
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
