import request from "@modules/api";

let timerIdByDebounce = 0

export const translateTypeUser = (type) => {
	switch (type) {
		case 'user':
			return 'Пользователь'
		case 'admin':
			return 'Администратор'
		case 'auditor':
			return 'Ревизор'
		case 'bookkeeper':
			return 'Бухгалтер'
		case 'bankingDepartment':
			return 'Банкоский отдел'
		default:
			return ''
	}
}

export const asyncDebounce = (url, cb, delay = 800) => {
	if (timerIdByDebounce) {
		clearTimeout(timerIdByDebounce)
	}
	timerIdByDebounce = setTimeout(async () => {
		const result = await request(url)
		cb(result)
	}, delay)
}
