import {
	UserOutlined,
	RadarChartOutlined,
	DollarCircleOutlined,
	BranchesOutlined,
	SplitCellsOutlined,
	BankOutlined,
	ExceptionOutlined,
} from "@ant-design/icons";

const getNuv = ({isAdmin}) => {
	if (isAdmin) {
		return adminNav;
	} else return otherNav;
};

const adminNav = [
	{
		key: "1",
		icon: <UserOutlined/>,
		label: "Пользователи",
		url: "/users",
	},
	{
		key: "2",
		icon: <RadarChartOutlined/>,
		label: "Фирмы",
		url: "/firms",
	},
	{
		key: "3",
		icon: <RadarChartOutlined/>,
		label: "Сторонние организации",
		url: "/side-firms",
	},
	{
		key: "4",
		icon: <DollarCircleOutlined/>,
		label: "Статьи расходов",
		url: "/expenses",
	},
	{
		key: "5",
		icon: <BranchesOutlined/>,
		label: "Цепочки согласований",
		url: "/chaotic-chain",
	},
	{
		key: "6",
		icon: <SplitCellsOutlined/>,
		label: "Подразделения",
		url: "/states",
	},
	{
		key: "7",
		icon: <BankOutlined/>,
		label: "Счета на оплату",
		url: "/bills-for-payment",
	},
	{
		key: "8",
		icon: <ExceptionOutlined/>,
		label: "Мои счета",
		url: "/my-bill",
	},
];

const otherNav = [
	{
		key: "1",
		icon: <ExceptionOutlined/>,
		label: "Мои счета",
		url: "/my-bill",
	},
	{
		key: "2",
		icon: <BankOutlined/>,
		label: "Счета на оплату",
		url: "/bills-for-payment",
	},
];

export default getNuv;
