import {useEffect, useState} from "react";
import {Bill} from "@components/Bill/Bill";
import {Space, Button, Modal, Row, Col, Select, Input, InputNumber, Upload, message} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import api from "@modules/api";

const MyBill = ({toggleLoading}) => {
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isCreateBill, setIsCreateBill] = useState(false);
	const [loadingDataFromBill, setLoadingDataFromBill] = useState(false);
	const [expenses, setExpenses] = useState([]);
	const [firms, setFirms] = useState([]);
	const [organizations, setOrganizations] = useState([]);
	const [bills, setBills] = useState([]);
	const [bill, setBill] = useState({
		priority: "low",
		files: [],
	});

	const resetBill = () =>
		setBill({
			priority: "low",
			files: [],
		});

	const getMyBills = async () => {
		const response = await api("bill/my");
		if (response.status === 200) {
			setBills(response.data);
		}
		toggleLoading();
	};

	useEffect(() => {
		(async () => {
			await getMyBills();
		})();
	}, []);

	const setNewBill = (data) =>
		setBill({
			...bill,
			...data,
		});

	const toggleIsOpenModal = () => setIsOpenModal((prev) => !prev);

	const toggleLoadingData = () => setLoadingDataFromBill((prev) => !prev);

	const fetchDataFromBill = async () => {
		toggleLoadingData();
		const response = await api("bill/data_from_create");
		toggleLoadingData();
		if (response.status === 200) {
			setExpenses(response.data.expenses);
			setFirms(response.data.firms);
			setOrganizations(response.data.organization);
			if (response.data.organization.length === 1) {
				setNewBill({
					organization: 0,
				});
			}
		}
	};

	const openModal = async () => {
		toggleIsOpenModal();
		await fetchDataFromBill();
	};

	const closeModal = () => {
		resetBill();
		toggleIsOpenModal();
	};

	const createBill = async () => {
		setIsCreateBill(true);
		const response = await api("bill/create", {
			method: "POST",
			body: {
				...bill,
				files: bill.files.map((file) => file.response.file.filename),
				organization: organizations[bill.organization],
			},
		});
		setBills([
			{
				...response.data,
				expenses: expenses.find((el) => el.id === bill.expenses),
				files: bill.files,
				firm: firms.find((el) => el.id === bill.firm),
				organization: organizations[bill.organization],
				priority: bill.priority,
				howToPay: bill.howToPay,
				comment: bill.comment,
				sum: bill.billSum,
			},
			...bills,
		]);
		resetBill();
		setIsCreateBill(false);
		toggleIsOpenModal();
	};

	const beforeUpload = (file) => {
		const mimeType = ["image/jpeg", "image/png", "application/pdf"];
		const isIMG = mimeType.some((type) => file.type === type);
		if (!isIMG) {
			message.error(`${file.name} не поддерживаемый формат файла`, 5);
		}
		return isIMG || Upload.LIST_IGNORE;
	};

	const handleChange = (info) => {
		const newFileList = info.fileList.map((file) => {
			if (file.response) {
				file.url = `api/upload/${file.response.file.filename}`;
			}
			return file;
		});
		setNewBill({
			files: newFileList,
		});
	};

	const setExpenseToBill = (expenses) => {
		setNewBill({expenses});
	};

	const setHowToPay = (e) => {
		setNewBill({howToPay: e.target.value});
	};

	const setBillSum = (e) => {
		setNewBill({billSum: e});
	};

	const setFirm = (firm) => {
		setNewBill({firm});
	};

	const setOrganization = (organization) => {
		setNewBill({organization});
	};

	const setComment = (e) => {
		setNewBill({comment: e.target.value});
	};

	const setPriority = (priority) => {
		setNewBill({priority});
	};

	const objectList = [
		{
			key: "expenses",
			title: "Статься расходов",
			component: (
				<Select
					style={{width: "100%"}}
					placeholder={"Выберете статью расходов"}
					value={bill.expenses}
					onChange={setExpenseToBill}
					options={expenses.map((el) => ({value: el.id, label: `${el.number} ${el.name}`, key: el.id}))}
				/>
			),
		},
		{
			key: "howToPay",
			title: "За что платим",
			component: <Input placeholder="Введите текст" onChange={setHowToPay} value={bill.howToPay}/>,
		},
		{
			key: "billFile",
			title: "Копия счета",
			component: (
				<Upload action="api/upload" listType="picture" beforeUpload={beforeUpload} multiple={true}
								onChange={handleChange} fileList={bill.files}>
					<Button type="primary" icon={<UploadOutlined/>}>
						Добавить
					</Button>
				</Upload>
			),
		},
		{
			key: "billSum",
			title: "Сумма счета",
			component: (
				<InputNumber
					min={0}
					controls={false}
					placeholder="Введите сумму счета"
					step={"0.01"}
					style={{width: "100%"}}
					addonAfter={"RUB"}
					onChange={setBillSum}
					value={bill.billSum}
				/>
			),
		},
		{
			key: "firm",
			title: "Фирма",
			component: (
				<Select
					style={{width: "100%"}}
					placeholder={"Выберете фирму"}
					value={bill.firm}
					onChange={setFirm}
					options={firms.map((el) => ({value: el.id, label: `${el.name}`, key: el.id}))}
				/>
			),
		},
		{
			key: "organization",
			title: "Организация",
			component: (
				<Select
					style={{width: "100%"}}
					placeholder={"Выберете организацию"}
					value={bill.organization}
					onChange={setOrganization}
					options={organizations.map((el, i) => ({
						value: i,
						label: el.label,
						key: i,
					}))}
				/>
			),
		},
		{
			key: "comment",
			title: "Комментарий",
			component: <Input placeholder="Введите текст" onChange={setComment} value={bill.comment}/>,
		},
		{
			key: "priority",
			title: "Приоритет",
			component: (
				<Select
					style={{width: "100%"}}
					placeholder={"Выберете организацию"}
					value={bill.priority}
					onChange={setPriority}
					options={[
						{
							value: "low",
							label: "Низкий",
						},
						{
							value: "medium",
							label: "Средний",
						},
						{
							value: "high",
							label: "Высокий",
						},
					]}
				/>
			),
		},
	];

	const checkRequired = () => {
		return bill.files.length === 0 || !bill.expenses || !bill.firm || !Object.hasOwn(bill, "organization") || !bill.howToPay || !bill.billSum;
	};

	return (
		<>
			<Space style={{marginBottom: 5}}>
				<Button type={"primary"} onClick={openModal}>
					Добавить
				</Button>
				{/*<Space>Фильтры: </Space>*/}
			</Space>
			{bills.map((el) => (
				<Bill bill={el} key={el.id}/>
			))}
			<Modal
				open={isOpenModal}
				width={1000}
				title={"Создание счета"}
				okText={"Создать"}
				cancelText={"Закрыть"}
				onCancel={closeModal}
				onOk={createBill}
				cancelButtonProps={{
					disabled: isCreateBill,
				}}
				okButtonProps={{
					loading: isCreateBill,
					disabled: checkRequired(),
				}}
				maskClosable={false}
				loading={loadingDataFromBill}
				closable={false}
			>
				<Space direction={"vertical"} style={{width: "100%"}}>
					<>
						{objectList.map((obj) => (
							<Row key={obj.key} style={{alignItems: "center"}}>
								<Col span={4}>
									<div
										style={
											obj.key !== "priority" && obj.key !== "comment"
												? {
													backgroundColor: "#f7baba",
													padding: "0 5px",
													borderRadius: "5px",
												}
												: {}
										}
									>
										{obj.title}
									</div>
								</Col>
								<Col span={19} offset={1}>
									{obj.component}
								</Col>
							</Row>
						))}
					</>
				</Space>
			</Modal>
		</>
	);
};

export default MyBill;
