import React, {useEffect, useState} from "react";
import {Bill} from "@components/Bill/Bill";
import api from "@modules/api";
import {Result, Select, Space} from 'antd'

export const BillsForPayment = ({toggleLoading}) => {
	const [bills, setBills] = useState([]);
	const [shoeResult, setShoeResult] = useState(false);
	const [forPayment, setForPayment] = useState(true);

	const fetchBills = async (forPay = true) => {
		const response = await api(`bill/payment?forPay=${forPay}`);
		if (response.status === 200) {
			setBills(response.data);
			if (response.data.length === 0) {
				setShoeResult(true)
			}
		}
		toggleLoading()
	}

	useEffect(() => {
		(async () => {
			await fetchBills()
		})();
	}, []);

	const removeBill = async (id) => {
		const newBills = bills.filter(el => el.id !== id)
		setBills(newBills)
		if (newBills.length === 0) {
			setShoeResult(true)
		}
	}

	const setFilter = async (v) => {
		toggleLoading()
		setForPayment(v === 'forPay')
		await fetchBills(v === 'forPay')
	}

	return <>
		<Space>Фильтр: <Select
			style={{width: 150}}
			defaultValue={'forPay'}
			onChange={setFilter}
			options={[
				{
					value: "forPay",
					label: "На оплату",
				},
				{
					value: "paid",
					label: "Пройденные",
				}
			]}
		/></Space>
		{bills.map(el => <Bill bill={el}
													 forPayment={forPayment}
													 toggleLoading={toggleLoading}
													 removeBill={removeBill}
													 key={el.id}/>)}
		{shoeResult && forPayment && <Result status="success"
																				 title="Нет счетов для оплаты"/>}
	</>
};
