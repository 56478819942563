import {useEffect, useState, useRef} from "react";
import api from "@modules/api";
import {Table, Input, Space, Button, Modal, Switch, Row, Col, Select} from "antd";
import {translateTypeUser} from "@modules/common";
import {SearchOutlined} from "@ant-design/icons";

const Users = ({toggleLoading}) => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [sideFirms, setSideFirms] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const searchInput = useRef(null);

  useEffect(() => {
    (async () => {
      const response = await Promise.all([api("user/list"), api("side-firms")]);
      if (response[0].status === 200) {
        setUsers(response[0].data);
      }
      if (response[1].data.status) {
        setSideFirms(response[1].data.data);
      }
      toggleLoading();
    })();
  }, []);

  const toggleModal = () => setIsOpenModal((prev) => !prev);

  const toggleConfirmLoading = () => setConfirmLoading((prev) => !prev);

  const getColumnSearchProps = (dataIndex, label) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
      <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Поиск по ${label}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" style={{width: 90}}>
            Поиск
          </Button>
          <Button
            onClick={() => {
              clearFilters && clearFilters();
              clearFilters && confirm();
            }}
            size="small"
            style={{width: 90}}
          >
            Сбросить
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({closeDropdown: false});
            }}
          >
            Фильтровать
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            Закрыть
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "ФИО",
      dataIndex: "fullName",
      key: "fullName",
      ...getColumnSearchProps("fullName", "ФИО"),
    },
    {
      title: "Доступ к проекту",
      dataIndex: "access",
      key: "access",
      render: (value) => (value?.status ? "Да" : "Нет"),
    },
    {
      title: "Роль",
      dataIndex: "type",
      key: "type",
      render: (value) => translateTypeUser(value),
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email", "почте"),
    },
  ];

  const onRow = (record) => ({
    onClick: () => {
      setUser({
        ...record,
        ...(record.access ? {access: record.access} : {access: {status: false}}),
      });
      toggleModal();
    },
  });

  const onChangeSwitch = (checked) => {
    setUser({
      ...user,
      access: {
        status: checked,
      },
    });
  };

  const onChangeUserType = (type) => {
    setUser({
      ...user,
      type,
    });
  };

  const onChangeSideFirm = (id) => {
    setUser({
      ...user,
      sideFirmsId: id,
    });
  };

  const handleOk = async () => {
    toggleConfirmLoading();
    await api("user/update", {
      method: "POST",
      body: user,
    });
    toggleConfirmLoading();
    setUsers(users.map((item) => (item.id === user.id ? user : item)));
    toggleModal();
  };

  return (
    <>
      <Table dataSource={users} columns={columns} onRow={onRow} rowClassName={"cursor_pointer"} />
      <Modal
        open={isOpenModal}
        title={`Редактирование пользователя ${user?.fullName}`}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={toggleModal}
        cancelButtonProps={{
          disabled: confirmLoading,
        }}
        closable={false}
      >
        <Space direction={"vertical"} style={{width: "100%"}}>
          <Row>
            <Col span={10}>Доступ к проекту</Col>
            <Col span={13} offset={1}>
              <Switch checked={user?.access?.status} onChange={onChangeSwitch} />
            </Col>
          </Row>
          <Row>
            <Col span={10}>Роль</Col>
            <Col span={13} offset={1}>
              <Select
                style={{width: "100%"}}
                value={user?.type}
                onChange={onChangeUserType}
                options={[
                  {
                    value: "user",
                    label: "Пользователь",
                  },
                  {
                    value: "admin",
                    label: "Администратор",
                  },
                  {
                    value: "auditor",
                    label: "Ревизор",
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col span={10}>Сторонняя фирма</Col>
            <Col span={13} offset={1}>
              <Select
                style={{width: "100%"}}
                value={user?.sideFirmsId}
                onChange={onChangeSideFirm}
                options={sideFirms.map((value) => ({value: value.id, label: value.name}))}
              />
            </Col>
          </Row>
        </Space>
      </Modal>
    </>
  );
};

export default Users;
